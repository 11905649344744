import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React from "react";

import { Head } from "~src/designSystem/sortLater/Head";
import { t } from "~src/designSystem/theme";
import { TABLET_MAX } from "~src/shared/styles/responsive";

import { RightTestimonies } from "./RightTestimonies";

interface IProps {
  title: string;
  children?: React.ReactNode;
}

/**
 * Layout for a page to authenticate.
 */
export const AuthPageLayout: React.FC<IProps> = ({ children, title }: IProps) => {
  return (
    <LoginPageWrapper>
      <Head title={title} />
      <TestimoniesContainer>
        <RightTestimonies />
      </TestimoniesContainer>
      <LoginArea>
        <LeftContainer>{children}</LeftContainer>
      </LoginArea>
    </LoginPageWrapper>
  );
};

const LoginPageWrapper = styled.div`
  background: ${t.colors.surfaceBackground.toString()};
  min-height: 100vh;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 50%);
  @media (max-width: ${TABLET_MAX}) {
    grid-template-columns: 100%;
  }
`;

const LoginArea = styled(motion.div)`
  display: flex;
  flex-direction: column;

  max-height: 100vh;
  overflow-y: auto;

  padding: 20px 35px;
  z-index: 1000;

  @media (max-width: ${TABLET_MAX}) {
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  margin: 0 21px;
  display: flex;
  flex-direction: column;

  max-width: 500px;
  @media (max-width: ${TABLET_MAX}) {
    max-width: 100%;
  }
`;

const TestimoniesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  max-height: 100vh;

  padding: 0 35px;

  background: url("/static/assets/login-background.png") #000000;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @media (max-width: ${TABLET_MAX}) {
    display: none;
  }
`;
